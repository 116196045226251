import { mapMutations, mapState } from 'vuex'

export default {
	mounted() {
		this.checkUrlParams()
	},
	methods: {
		...mapMutations('user', [
			'setActionUrl'
		]),
        ...mapMutations('app', [
			'setEndpointBase',
            'setCurrentId',
            'setCustomMethod'
		]),
		checkUrlParams() {
			const urlParams = location.href.split('/')

            if (urlParams.length >= 4) {
				const endpoint = urlParams[3]
				const method = (urlParams[4] ? urlParams[4] : null)
				const entity = (urlParams[5] ? urlParams[5] : null)

                this.setEndpointBase(endpoint)
                this.setCurrentId(entity)

                if (method) {
                    if (['edit', 'create'].includes(method)) {
						let methodDefault = `modal${method.charAt(0).toUpperCase()}${method.slice(1)}`
                        this[methodDefault](parseInt(entity))
                    } else {
                        this.setCustomMethod(method)
                    }
                }
            }
		}
	},
	computed: {
		...mapState('user', [
			'actionUrl'
		])
	},
	watch: {
		actionUrl(v) {
			if (v) {
				this.checkUrlParams()
				this.setActionUrl(false)
			}
		}
	}
}
