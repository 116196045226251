<template>
	<article class="block placeholder">
		<div class="block__content">
			<span class="block__name">
				<b-skeleton width="40%" height="15"></b-skeleton>
				<b-skeleton width="80%" height="10"></b-skeleton>
			</span>
		</div>
	</article>
</template>

<script>
export default {
	name: 'Placeholder'
}
</script>
